import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Platform } from '@ionic/angular';
import { StartPage } from './start/start.page';
import { HomePage } from './home/home.page';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private deeplinks: Deeplinks,
    private router: Router,
    private zone: NgZone,
    private platform: Platform
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.initDeeplinks();
    });
  }

  initDeeplinks() {
    this.deeplinks.route({ '/home': 'home' })
    .subscribe(match => {
        const path = `/${match.$route}`;
        // Run the navigation in the Angular zone
        this.zone.run(() => {
          this.router.navigateByUrl(path);
        });
      },
      nomatch => {
        console.log("Deeplink that didn't match", nomatch);
      });
  }
}
