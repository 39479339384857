import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'register-phone',
    loadChildren: () => import('./auth/register-phone/register-phone.module').then( m => m.RegisterPhonePageModule)
  },
  {
    path: 'valid-phone',
    loadChildren: () => import('./auth/valid-phone/valid-phone.module').then( m => m.ValidPhonePageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'performer/:userId',
    loadChildren: () => import('./dashboard/performer/performer.module').then( m => m.PerformerPageModule)
  },
  {
    path: 'find-performer',
    loadChildren: () => import('./dashboard/find-performer/find-performer.module').then( m => m.FindPerformerPageModule)
  },
  {
    path: 'select-service',
    loadChildren: () => import('./dashboard/select-service/select-service.module').then( m => m.SelectServicePageModule)
  },
  {
    path: 'service-modal',
    loadChildren: () => import('./modal/service-modal/service-modal.module').then( m => m.ServiceModalPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./dashboard/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'listing-performers',
    loadChildren: () => import('./dashboard/listing-performers/listing-performers.module').then( m => m.ListingPerformersPageModule)
  },
  {
    path: 'opinions-modal',
    loadChildren: () => import('./modal/opinions-modal/opinions-modal.module').then( m => m.OpinionsModalPageModule)
  },
  {
    path: 'chat/:userId',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'remember-password',
    loadChildren: () => import('./auth/remember-password/remember-password.module').then( m => m.RememberPasswordPageModule)
  },
  {
    path: 'chat-history',
    loadChildren: () => import('./chat-history/chat-history.module').then( m => m.ChatHistoryPageModule)
  },
  {
    path: 'contractor-tabs',
    loadChildren: () => import('./accounts/contractor-tabs/contractor-tabs.module').then( m => m.ContractorTabsPageModule)
  },
  {
    path: 'report-modal',
    loadChildren: () => import('./modal/report-modal/report-modal.module').then( m => m.ReportModalPageModule)
  },
  {
    path: 'employer-tabs',
    loadChildren: () => import('./accounts/employer-tabs/employer-tabs.module').then( m => m.EmployerTabsPageModule)
  },
  {
    path: 'note-modal',
    loadChildren: () => import('./modal/note-modal/note-modal.module').then( m => m.NoteModalPageModule)
  },
  {
    path: 'send-suggestion',
    loadChildren: () => import('./send-suggestion/send-suggestion.module').then( m => m.SendSuggestionPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then( m => m.TutorialPageModule)
  },
  {
    path: 'regulation',
    loadChildren: () => import('./regulation/regulation.module').then( m => m.RegulationPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule)
  },  {
    path: 'policy',
    loadChildren: () => import('./policy/policy.module').then( m => m.PolicyPageModule)
  }











];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
