import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';

import locale from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';

import { environment } from '../environments/environment';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';


import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Globalization } from '@ionic-native/globalization/ngx';

import { IonicGestureConfig } from '../utils/IonicGestureConfig'
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => getFirestore()),
        AngularFireModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
          }),
    ],
    providers: [
        Geolocation,
        NativeGeocoder,
        Facebook,
        GooglePlus,
        SmsRetriever,
        LocalNotifications,
        Clipboard,
        SignInWithApple,
        InAppBrowser,
        OpenNativeSettings,
        PhotoViewer,
        Deeplinks,
        Globalization,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: LOCALE_ID,
            useValue: "pl-PL"
        },
        {
          provide: HAMMER_GESTURE_CONFIG,
          useClass: IonicGestureConfig
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(locale, 'pl');
  }
}
